<template>
  <div>
    <a-modal v-model="visible" :title="$t('setting_up_the_speakers')" @ok="handleOk">
      <div v-for="(item, index) in clone_column_defs" :key="index" class="flex my-2">
        <button
            type="button"
            class="flex items-center ant-btn ant-btn-sm justify-center mr-1"
            style="padding: 0px 0px 0px 5px; height: 20px; width: 30px;"
            @click="moveDown(index)"
            :disabled="index === clone_column_defs.length - 1"
        >
          <a-icon type="down" />
        </button>
        <button
            type="button"
            class="flex items-center ant-btn ant-btn-sm justify-center mr-2"
            style="padding: 0px 0px 0px 5px; height: 20px; width: 30px;"
            @click="moveUp(index)"
            :disabled="index === 0"
        >
          <a-icon type="up" />
        </button>
        <a-checkbox v-model="item.use">
          {{item.title}}
        </a-checkbox>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "SettingUpTheSpeakers",
  props: {
    column_defs: {
      type: Array,
      default: []
    },
    pages_front:{
      type:Object,
    },
  },
  data() {
    return {
      visible: false,
      clone_column_defs: [...this.column_defs],
    };
  },
  watch: {
    column_defs(val) {
      this.clone_column_defs = [...val];
    }
  },
  methods: {
    ...mapActions({
      PROFILE_COLUMN_DEFS:'user/PROFILE_COLUMN_DEFS'
    }),
    showModal() {
      this.visible = true;
    },
    async handleOk(e) {
      console.log(e);
      // this.$emit('update:column_defs', this.clone_column_defs);
      await this.PROFILE_COLUMN_DEFS({
        data:{
          column_defs:this.clone_column_defs,
          pages_front:this.pages_front.uid,
        }
      })
      this.visible = false;
      this.$emit('updated_table')
    },
    moveUp(index) {
      if (index > 0) {
        const temp = this.clone_column_defs[index - 1];
        this.$set(this.clone_column_defs, index - 1, this.clone_column_defs[index]);
        this.$set(this.clone_column_defs, index, temp);
      }
    },
    moveDown(index) {
      if (index < this.clone_column_defs.length - 1) {
        const temp = this.clone_column_defs[index + 1];
        this.$set(this.clone_column_defs, index + 1, this.clone_column_defs[index]);
        this.$set(this.clone_column_defs, index, temp);
      }
    },
  }
};
</script>
